import React, { useState } from 'react'
import { useRecoilValue } from 'recoil'
import { isMobileState } from '@/recoil/layout'
import PropTypes from 'prop-types'
import { GridContainer } from '@/components/Grid'
import Select from '@/components/Forms/Inputs/Select'

import { Container, Item, ItemsList, SortLabel, Wrapper } from './style'

const Filters = ({ label, filtersItems, placeholder, onChangeCallback }) => {
  const [active, seActive] = useState('all')
  const isMobile = useRecoilValue(isMobileState)

  const onChange = (e) => {
    if ((!placeholder && isMobile)) {
      const id = filtersItems.find((i) => i.label === e).catId
      seActive(id)
      onChangeCallback(id)
    } else {
      seActive(e)
      onChangeCallback(e)
    }
  }

  const handleKeyPress = (catId) => {
    if (event.key == 'Enter') {
      onChange(catId)
    }
  }

  let items = []

  if (isMobile && !placeholder) {
    for (const item in filtersItems) { items.push(filtersItems[item].label) }
  } else if (placeholder) { items = filtersItems }

  return (
    <Container>
      <GridContainer>
        <Wrapper>
          <SortLabel> { label }</SortLabel>
          <ItemsList className={ `${!filtersItems.length && 'empty'}` }>
            {!placeholder && !isMobile &&
            filtersItems.map(({ label, catId }, i) => (
              <Item role='button' tabIndex={ 0 } key={ i } className={ catId === active ? 'active' : '' } onClick={ _ => onChange(catId) } onKeyDown={ _ => handleKeyPress(catId) }>{ label }</Item>
            ))}
            {(placeholder || isMobile) && (
              <Select
                label={ placeholder || items[0] }
                list={ items }
                onChange={ onChange }
                name='filterSelect'
                withDropDownIcon
                isFilter
              />)}
          </ItemsList>
        </Wrapper>
      </GridContainer>
    </Container>
  )
}

Filters.propTypes = {
  label: PropTypes.string,
  filtersItems: PropTypes.array,
  placeholder: PropTypes.string,
  onChangeCallback: PropTypes.func
}

export default Filters
