import styled from '@emotion/styled'
import { ButtonWrapper as Button } from '@/components/Button/style'

export const Container = styled.div`
  width: 100%;
  padding-top: 100%;
  position: relative;
`

export const DateWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const DateItem = styled.div`

`

export const DateSeparator = styled.div`
  font-size: 5rem;
  color: ${({ theme }) => theme.colors.green};
  margin: 0 1rem;

  &:before {
    content: '-'
  }
`

export const Day = styled.div`
  font-family: ${({ theme }) => theme.fonts.serif};
  font-size: 5rem;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.green};
  margin-bottom: -0.5rem;
  letter-spacing: -5px;
`

export const Month = styled.div`
  font-family: ${({ theme }) => theme.fonts.sansSerif};
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.green};
  text-transform: uppercase;
  letter-spacing: .1rem;
`

export const Year = styled.div`
  font-family: ${({ theme }) => theme.fonts.sansSerif};
  font-size: 1rem;
  line-height: 100%;
  margin-top: .5rem;
  color: ${({ theme }) => theme.colors.green};
  margin-bottom: -0.5rem;
  // letter-spacing: -5px;
`
export const InfosWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`

export const Name = styled.div`
  font-family: ${({ theme }) => theme.fonts.serif};
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 2rem;
  color: ${({ theme }) => theme.colors.green};
  margin-bottom: 0.5rem;
`

export const Hours = styled.div`
  font-family: ${({ theme }) => theme.fonts.sansSerif};
  font-size: 1rem;
  line-height: 1.4rem;
  letter-spacing: .1rem;
  color: ${({ theme }) => theme.colors.green};
`

export const References = styled.div`
  font-family: ${({ theme }) => theme.fonts.sansSerif};
  font-size: 1rem;
  line-height: 1.4rem;
  letter-spacing: .1rem;
  color: ${({ theme }) => theme.colors.green};
`

export const Wrapper = styled.div`
  padding: 3rem;
  padding-top: 2.2rem;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.lightGreen5};
  transition: background-color ease-out .3s;

  &.status-closed {
    opacity: .7;
  }

  ${Day},
  ${Month},
  ${Name},
  ${Hours},
  ${DateSeparator},
  ${References} {
    transition: color ease-out .3s;
  }

  &.status-open {
    &:hover {
      background-color: ${({ theme }) => theme.colors.green};

      ${Day},
      ${Month},
      ${Name},
      ${Year},
      ${DateSeparator} {
        color: ${({ theme }) => theme.colors.white};
      }

      ${Hours},
      ${References} {
        color: ${({ theme }) => `${theme.colors.white}40`};
      }
    }
  }
`
