import React, { useEffect, useRef } from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import Link from '@/components/LocalizedGatsbyLink'
import { useRecoilValue } from 'recoil'
import { isMobileState } from '@/recoil/layout'

import moment from 'moment'

import Layout from '@/layouts/Default'
import { useWatchSections } from '@/hook/index'
import { useLocation } from '@reach/router'
import Hero from '@/components/Hero'
import HeroCard from '@/components/Hero/HeroCard'
import BlockTextImages from '@/components/Block/TextImages'
import BlockSlider from '@/components/Block/TextSlider'
import Verbatim from '@/components/Verbatim'
import Experiences from '@/components/Experiences'
import Community from '@/components/Community'
import Events from '@/components/Events'
import WinerySlider from '@/components/WinerySlider'
import SectionsNav from '@/components/SectionsNav'
import StoreLocator from '@/components/StoreLocator'
import Button from '@/components/Button'
import CircularLabelButton from '@/components/CircularLabelButton'
import slugify from '@/utils/slugify'
import resolveFileURL from '@/utils/resolveFileURL'
import resolveAssetURL from '@/utils/resolveAssetURL'
import { initTock, resetTock } from '@/utils/tock'

import {
  Wrapper
} from '@/styles/pages/visit-winery/style'
import { getButtons } from '@/utils/buttons'

const VisitWinery = ({ data, pageContext: { requireAgeGate, pagePath, marketCode: pageMarketCode } }) => {
  const isMobile = useRecoilValue(isMobileState)
  const place = useRef()
  const tasting = useRef()
  const bar = useRef()
  const experiences = useRef()
  const eventsRef = useRef()
  const tour = useRef()

  const [Section, sections] = useWatchSections(
    [
      place,
      tasting,
      bar,
      experiences,
      eventsRef,
      tour
    ],
    {
      offsetTop: 132 /* header + sectionsNav todo set dynamic value */
    }
  )

  const socialData = data.drupal.nodeById.fieldCellarDoorOurCommunity?.entity

  const getSocialList = _ => {
    const socials = []
    for (let i = 0; i < socialData?.fieldSocialPostsList.entity.fieldSocialPostItem.length; i++) {
      const item = socialData?.fieldSocialPostsList.entity.fieldSocialPostItem[i].entity

      socials.push({
        url: item.fieldSocialPostItemLink.uri,
        coverImg: item.fieldSocialPostItemCoverImg.url,
        coverAlt: item.fieldSocialPostItemCoverImg.alt,
        logo: item.fieldSocialPostLogo.url,
        logoAlt: item.fieldSocialPostLogo.alt,
        postUsername: item.fieldSocialPostItemUsername,
        postSocialAccount: item.fieldSocialPostUserAccount.title,
        postSocialAccountUrl: item.fieldSocialPostUserAccount.url.path
      })
    }
    return socials
  }

  const socialsList = getSocialList()

  const contactDetailsData = data.drupal.nodeById?.fieldCellarDoorContactDetail.entity
  const virtualTourData = data.drupal.nodeById?.fieldCellarDoorVirtualTour.entity
  const ourPlaceData = data.drupal.nodeById?.fieldCellarDoorOurPlace.entity
  const tastingXpData = data.drupal.nodeById?.fieldCellarDoorTastingExp.entity
  const customXpData = data.drupal.nodeById?.fieldCellarDoorCustomExp.entity
  const restaurantData = data.drupal.nodeById?.fieldCellarDoorRestaurant.entity

  const nid = data.drupal.nodeById.nid

  const location = useLocation()
  const nextData = data.drupal.nodeById?.fieldNextCellarDoor.entity

  const heroItems = _ => {
    return [
      {
        icon: 'icon-clock',
        top: contactDetailsData.fieldContactDetailsOpeningT,
        label: contactDetailsData.fieldContactDetailsOpening.split('\r\n')[0],
        content: contactDetailsData.fieldContactDetailsOpening.split('\r\n')[1]
      },
      {
        icon: 'icon-calendar',
        top: contactDetailsData.fieldContactDetailsClosingT,
        label: contactDetailsData.fieldContactDetailsClosing,
        content: ''
      }
    ]
  }

  const ourPlaceButtons = _ => {
    const buttons = []
    // console.log("ourPlaceData.fieldStandardTextBlock.entity")
    // console.log(ourPlaceData.fieldStandardTextBlock.entity)
    // if (
    //   ourPlaceData.fieldStandardTextBlock.entity.fieldStandardCta &&
    //   ourPlaceData.fieldStandardTextBlock.entity.fieldStandardCta.title
    // ) {
    //   buttons.push(
    //     <Button
    //       key={ 1 }
    //       target='_blank'
    //       type='green'
    //       to={ ourPlaceData.fieldStandardTextBlock.entity.fieldStandardCta.url.path }
    //     >
    //       {ourPlaceData.fieldStandardTextBlock.entity.fieldStandardCta.title}
    //     </Button>
    //   )
    // }
    //
    // if (
    //   ourPlaceData.fieldStandardTextBlock.entity.fieldStandardContentCta2Link &&
    //   ourPlaceData.fieldStandardTextBlock.entity.fieldStandardContentCta2Link.title &&
    //   ourPlaceData.fieldStandardTextBlock.entity.fieldStandardContentDownload
    // ) {
    //   buttons.push(
    //     <Button
    //       key={ 2 }
    //       target='_blank'
    //       type='white'
    //       to={ resolveAssetURL(ourPlaceData.fieldStandardTextBlock.entity.fieldStandardContentDownload?.entity?.url) }
    //     >
    //       {ourPlaceData.fieldStandardTextBlock.entity.fieldStandardContentCta2Link.title}
    //     </Button>
    //   )
    // }

    return buttons
  }

  const ourPlaceImages = _ => {
    return Array.from(ourPlaceData.fieldStandardImageCarrousel.entity.fieldCarrouselImageItem, item => item.entity.fieldImageItem)
  }
  const tastingXpImages = _ => {
    return Array.from(tastingXpData.fieldStandardImageCarrousel.entity.fieldCarrouselImageItem, item => item.entity.fieldImageItem)
  }

  const tastingTockId = tastingXpData.fieldStandardContentBlock[0]?.entity.fieldStandardContentCta2Link?.url.path !== '' ? tastingXpData.fieldStandardContentBlock[0]?.entity.fieldStandardContentCta2Link?.url.path.split('/')[1] : null

  const tastingXpButtons = _ => {
    const buttons = []

    if (tastingTockId) {
      buttons.push(
        <div
          key={ 2 }
          id='Tock_widget_container'
          data-tock-display-mode='Button'
          data-tock-color-mode='Blue'
          data-tock-locale='en-nz'
          data-tock-timezone='Pacific/Auckland'
          data-tock-offering={ tastingTockId }
        >
          {tastingXpData.fieldStandardContentBlock[0]?.entity.fieldStandardContentCta2Link?.title}
        </div>
      )
    }

    if (
      tastingXpData.fieldStandardContentBlock[0]?.entity.fieldStandardCta &&
      tastingXpData.fieldStandardContentBlock[0]?.entity.fieldStandardCta.title
    ) {
      buttons.push(
        <Button
          key={ 1 }
          target='_blank'
          type="white"
          to={ resolveAssetURL(tastingXpData.fieldStandardContentBlock[0]?.entity.fieldStandardContentDownload?.entity?.url) }
        >
          {tastingXpData.fieldStandardContentBlock[0]?.entity.fieldStandardCta?.title}
        </Button>
      )
    }

    return buttons
  }

  const restaurantImages = _ => {
    return Array.from(restaurantData.fieldStandardImageCarrousel.entity.fieldCarrouselImageItem, item => item.entity.fieldImageItem.url)
  }

  const tockURL = restaurantData.fieldStandardContentBlock[0]?.entity.fieldStandardContentCta2Link?.url.path
  const restaurantTockId = (tockURL && tockURL !== '') ? (tockURL.split('/')[1] ? tockURL.split('/')[1] : tockURL) : null

  const restaurantButtons = _ => {
    const buttons = []

    if (restaurantTockId) {
      if (restaurantTockId.indexOf('http') > -1) {
        buttons.push(
          <Button
            to={ restaurantTockId }
            target='_blank'
            type='green'
          >
            Book now
          </Button>
        )
      } else {
        buttons.push(
          <div
            key={ 2 }
            id='Tock_widget_container'
            data-tock-display-mode='Button'
            data-tock-color-mode='Blue'
            data-tock-locale='en-nz'
            data-tock-timezone='Pacific/Auckland'
            data-tock-offering={ restaurantTockId }
          >
            {restaurantData.fieldStandardContentBlock[0]?.entity.fieldStandardContentCta2Link?.title}
          </div>
        )
      }
    }

    if (restaurantData.fieldStandardContentBlock[0]?.entity.fieldStandardCta && restaurantData.fieldStandardContentBlock[0]?.entity.fieldStandardContentDownload) {
      buttons.push(
        <Button
          key={ 1 }
          target='_blank'
          type="white"
          to={ resolveAssetURL(restaurantData.fieldStandardContentBlock[0]?.entity.fieldStandardContentDownload?.entity?.url) }
        >
          { restaurantData.fieldStandardContentBlock[0]?.entity.fieldStandardCta?.title }
        </Button>
      )
    }

    return buttons
  }

  const nextItems = [
    {
      name: nextData.fieldQuoteText,
      background: isMobile ? nextData.fieldQuoteBackgroundMobile.url : nextData.fieldQuoteBackgroundDesktop.url,
      backgroundAlt: isMobile ? nextData.fieldQuoteBackgroundMobile.alt : nextData.fieldQuoteBackgroundDesktop.alt,
      image: nextData.fieldQuoteThumbnailImage.url,
      label: nextData.fieldStandardQuoteCta.title,
      href: nextData.fieldStandardQuoteCta.url.path
    }
  ]

  const experienceItems = data.drupal.nodeById?.fieldCellarDoorExperiences.map((experience) => (
    {
      status: experience.entity?.fieldExperienceItem.entity?.status,
      name: experience.entity?.fieldExperienceItem.entity?.fieldExperienceName,
      tags: experience.entity?.fieldExperienceItem.entity?.fieldExperienceCategory.map((tag) => tag.entity?.name),
      image: experience.entity?.fieldExperienceItem.entity?.fieldExperienceImageDesktop.url,
      price: experience.entity?.fieldExperienceItem.entity?.fieldExperienceBookingDetails.entity?.fieldBookingDetailsPrice,
      link: experience.entity?.fieldExperienceItem.entity?.path.alias
    }
  )).filter((xp) => !!xp.status)

  const events = data.drupal.eventsQuery.entities
    .filter((event) => event?.fieldEventTypes.includes(slugify(data.drupal.nodeById.fieldCellarDoorName)))
    .map((event) => {
      const date = event?.fieldEventEndDate?.value ? event?.fieldEventEndDate?.value : event?.fieldEventStartDate?.value
      return {
        name: event?.title,
        url: event?.path.alias,
        startDate: event?.fieldEventStartDate?.value,
        endDate: event?.fieldEventEndDate?.value,
        ref: event?.fieldEventLocation,
        hours: event?.fieldEventHours,
        onGoing: moment.utc(new Date()).isBefore(date),
        country: event?.fieldEventCountryLocation,
        city: event?.fieldEventLocation,
        content: event?.fieldEventDescription,
        images: [event?.fieldEventCover?.url],
        timestamp: moment(event?.fieldEventStartDate?.value).unix()
      }
    })
    .filter((event) => event.onGoing)
    .sort((ea, eb) => ea.timestamp - eb.timestamp)

  const meta = {
    title: data.drupal.nodeById?.fieldCellarDoorMetaTags?.entity?.fieldMetaTitle,
    description: data.drupal.nodeById?.fieldCellarDoorMetaTags?.entity?.fieldMetaDescription,
    bypassAgeGate: data.drupal.nodeById?.fieldCellarDoorMetaTags?.entity?.fieldMetaTagsBypassAgeGate,
    slug: data.drupal.nodeById?.fieldCellarDoorMetaTags?.entity?.fieldMetaSlugUrl,
    image: data.drupal.nodeById?.fieldCellarDoorMetaTags?.entity?.fieldMetaOpenGraphImage?.url
  }

  const richResultSchema = {
    '@context': 'https://schema.org/',
    '@type': 'Place',
    name: data.drupal.nodeById.title,
    url: location.href
  }

  useEffect(() => {
    return () => {
      resetTock()
    }
  }, [])

  useEffect(() => {
    if (!isMobile) {
      resetTock()
    }
    initTock(data.drupal.nodeById.fieldTockBusinessName)
  }, [nid])

  return (
    <Layout entityBundle='cellar_door' path={ pagePath } requireAgeGate={ !meta.bypassAgeGate } pageType='Editorial' pageMarketCode={ pageMarketCode }>
      <Helmet>
        <title>{meta.title}</title>
        <meta name='description' content={ meta.description } />
        <meta property='og:title' content={ meta.title } />
        <meta property='og:description' content={ meta.description } />
        <meta property='og:url' content={ `${process.env.URL || process.env.GATSBY_DRUPAL_URL}${pagePath}` } />
        <meta property='og:site_name' content='Cloudy Bay' />
        <meta property='og:type' content='website' />
        { meta.image && <meta property='og:image' content={ meta.image } />}
        <script type='application/ld+json'>
          {JSON.stringify(richResultSchema)}
        </script>
      </Helmet>
      <Wrapper>
        <Hero
          id='textcercle1'
          subtitle={ data.drupal.nodeById.fieldCellarDoorExpSubtitle }
          title={ data.drupal.nodeById.title }
          text={ data.drupal.nodeById.fieldCellarDoorDescription }
          image={ isMobile ? data.drupal.nodeById.fieldCellarDoorBackMobile?.url : data.drupal.nodeById.fieldCellarDoorBackDesktop.url }
          actionLabel={ data.drupal.nodeById.fieldCellarDoorHeadCtaLabel }
        >
          <HeroCard
            title={ contactDetailsData.fieldContactDetailsTitle }
            text={ contactDetailsData.fieldContactDetailsSubtitle }
            items={ heroItems() }
            display='grid'
            block='visit-winery'
            actions={ getButtons(contactDetailsData.fieldContactDetailsLinks) }
          />
        </Hero>
        <SectionsNav sections={ sections } />
        <Section name='our place' id='our-place' ref={ place }>
          <BlockSlider
            subtitle={ ourPlaceData.fieldStandardContentBlock[0]?.entity.fieldStandardSubtitle }
            title={ ourPlaceData.fieldStandardContentBlock[0]?.entity.fieldStandardTitle }
            text={ ourPlaceData.fieldStandardContentBlock[0]?.entity.fieldStandardDescription }
            actions={ getButtons([ourPlaceData.fieldStandardContentBlock[0]?.entity.fieldStandardCta, ourPlaceData.fieldStandardContentBlock[0]?.entity?.fieldStandardContentCta2Link], ourPlaceData.fieldStandardContentBlock[0]?.entity.fieldStandardContentDownload?.entity?.url) }
            images={ ourPlaceImages() }
          />
        </Section>
        <Section name='tasting experience' id='tasting-experience' ref={ tasting }>
          <BlockSlider
            id='textcercle1'
            subtitle={ tastingXpData.fieldStandardContentBlock[0]?.entity.fieldStandardSubtitle }
            title={ tastingXpData.fieldStandardContentBlock[0]?.entity.fieldStandardTitle }
            text={ tastingXpData.fieldStandardContentBlock[0]?.entity.fieldStandardDescription }
            actions={ getButtons([tastingXpData.fieldStandardContentBlock[0]?.entity.fieldStandardCta, tastingXpData.fieldStandardContentBlock[0]?.entity?.fieldStandardContentCta2Link], tastingXpData.fieldStandardContentBlock[0]?.entity.fieldStandardContentDownload?.entity?.url) }
            display='inverted'
            images={ tastingXpImages() }
          />
        </Section>
        <Section name={ data.drupal.nodeById.title.toLowerCase() === 'marlborough' ? "Jack's Raw Bar" : 'restaurant' } id='restaurant' ref={ bar }>
          <BlockSlider
            id='textcercle2'
            subtitle={ restaurantData.fieldStandardContentBlock[0]?.entity.fieldStandardSubtitle }
            title={ restaurantData.fieldStandardContentBlock[0]?.entity.fieldStandardTitle }
            text={ restaurantData.fieldStandardContentBlock[0]?.entity.fieldStandardDescription }
            actions={ getButtons([restaurantData.fieldStandardContentBlock[0]?.entity.fieldStandardCta, restaurantData.fieldStandardContentBlock[0]?.entity.fieldStandardContentCta2Link], restaurantData.fieldStandardContentBlock[0]?.entity?.fieldStandardContentDownload?.entity?.url) }
            images={ restaurantImages() }
          />
        </Section>
        <Section name='private experiences' id='private-experiences' ref={ experiences }>
          <Experiences
            subtitle={ data.drupal.nodeById?.fieldCellarDoorExpSubtitle }
            title={ data.drupal.nodeById?.fieldCellarDoorExpTitle }
            action={
              (data.drupal.nodeById?.fieldCellarDoorExpBook?.entity?.url && data.drupal.nodeById?.fieldCellarDoorExpBook?.description) && (<Button target='_blank' to={ resolveAssetURL(data.drupal.nodeById?.fieldCellarDoorExpBook.entity.url) }>{data.drupal.nodeById?.fieldCellarDoorExpBook.description}</Button>)
            }
            items={ experienceItems }
          />
          <BlockTextImages
            subtitle={ customXpData.fieldStandardTextBlock.entity.fieldStandardSubtitle }
            title={ customXpData.fieldStandardTextBlock.entity.fieldStandardTitle }
            text={ customXpData.fieldStandardTextBlock.entity.fieldStandardDescription }
            actions={ getButtons([customXpData.fieldStandardTextBlock?.entity?.fieldStandardCta]) }
            display='small'
            images={ [
              customXpData.fieldStandard2imagesBlock.entity.fieldLeftImage?.url,
              customXpData.fieldStandard2imagesBlock.entity.fieldRightImage?.url
            ] }
            leftAlt={ customXpData.fieldStandard2imagesBlock.entity.fieldLeftImage?.alt }
            rightAlt={ customXpData.fieldStandard2imagesBlock.entity.fieldRightImage?.alt }
          />
        </Section>
        {events.length > 0 && (
          <Section name='events' id='events' ref={ eventsRef }>
            <Events
              title='Upcoming Events'
              subtitle='events'
              items={ events }
            />
          </Section>
        )}
        {virtualTourData && virtualTourData.fieldQuoteText && <Section name='virtual tour' id='virtual-tour' ref={ tour }>
          <Verbatim
            text={ virtualTourData.fieldQuoteText }
            background={ isMobile ? virtualTourData.fieldQuoteBackgroundMobile?.url.path : virtualTourData.fieldQuoteBackgroundDesktop?.url.path }
            image={ virtualTourData.fieldQuoteThumbnailImage }
            action={
              <CircularLabelButton
                id='textcercle3'
                label='Discover'
                color='white'
                nbLabels={ isMobile ? 3 : 5 }
              />
            }
            display='inverted'
          />
        </Section> }
        {socialData && <Community
          title={ socialData?.fieldSocialPostsList.entity.fieldSocialListTitle }
          subtitle={ socialData?.fieldSocialPostsList.entity.fieldSocialListSubtitle }
          text={ socialData?.fieldSocialPostsList.entity.fieldSocialListDescription }
          action={
            <CircularLabelButton
              id='textcercle4'
              label={ socialData?.fieldSocialPostsList.entity.fieldSocialListCta?.title }
              to={ socialData?.fieldSocialPostsList.entity.fieldSocialListCta?.url.path }
              target='_blank'
              nbLabels={ isMobile ? 3 : 5 }
            />
          }
          items={ socialsList }
        />}
        <WinerySlider
          id='textcercle2'
          label={ nextData.fieldStandardQuoteSubtitle }
          items={ nextItems }
        />
        {/* <StoreLocator /> */}
      </Wrapper>
    </Layout>
  )
}

export const query = graphql`
  query VisitUsQuery($nid: String!, $marketId: String!) {
    drupal {
      nodeById(id: $nid) {
        title
        ... on Drupal_NodeCellarDoor {
          nid
          fieldTockBusinessName
          fieldCellarDoorName
          fieldCellarDoorExpBook {
            entity {
              url
              uri {
                url
              }
            }
            description
          }
          fieldCellarDoorMetaTags {
            entity {
              ... on Drupal_ParagraphMetaTags {
                id
                fieldMetaDescription
                fieldMetaTitle
                fieldMetaTagsBypassAgeGate
                fieldMetaSlugUrl
                fieldMetaOpenGraphImage {
                  url
                }
              }
            }
          }
          fieldCellarDoorOurCommunity {
            entity {
              ... on Drupal_NodeStandardSocialPosts {
                fieldSocialPostsList {
                  entity {
                    ... on Drupal_ParagraphSocialPostItemList {
                      fieldSocialListCta {
                        uri
                        title
                        options
                        url{
                          path
                        }
                      }
                      fieldSocialPostItem {
                        entity {
                          ... on Drupal_ParagraphSocialPostItem {
                            fieldSocialPostLogo {
                              height
                              url
                              width
                              targetId
                              alt
                              title
                            }
                            fieldSocialPostItemLink {
                              uri
                              title
                              options
                            }
                            fieldSocialPostItemCoverImg {
                              url
                              alt
                            }
                            fieldSocialPostItemUsername
                            fieldSocialPostUserAccount {
                              title
                              url {
                                path
                              }
                            }
                          }
                        }
                      }
                      fieldSocialListTitle
                      fieldSocialListSubtitle
                      fieldSocialListDescription
                    }
                  }
                }
                fieldSocialPostsMarkets {
                  targetId
                }
              }
            }
          }
          fieldCellarDoorExpTitle
          fieldCellarDoorExpSubtitle
          fieldCellarDoorContactDetail {
            entity {
              ... on Drupal_ParagraphContactDetails {
                id
                fieldContactDetailsTitle
                fieldContactDetailsSubtitle
                fieldContactDetailsClosing
                fieldContactDetailsClosingT
                fieldContactDetailsOpening
                fieldContactDetailsOpeningT
                fieldContactDetailsLinks {
                  options
                  url {
                    path
                  }
                  title
                }
              }
            }
          }
          fieldCellarDoorBackDesktop {
            url
            title
            alt
          }
          fieldCellarDoorBackMobile {
            url
            title
            alt
          }
          fieldCellarDoorCoverImage {
            url
            title
            alt
          }
          fieldCellarDoorDescription
          fieldCellarDoorMenuBackImg {
            url
            title
            alt
          }
          fieldCellarDoorHeadCtaLabel
          fieldCellarDoorVirtualTour {
            entity {
              ... on Drupal_ParagraphStandardQuoteBlock {
                id
                fieldQuoteBackgroundDesktop {
                  url
                  title
                  alt
                }
                fieldQuoteBackgroundMobile {
                  alt
                  title
                  url
                }
                fieldQuoteThumbnailImage {
                  alt
                  title
                  url
                }
                fieldQuoteText
                fieldQuoteThumbnailPosition
                fieldStandardQuoteCta {
                  url {
                    path
                  }
                  title
                  options
                }
                fieldStandardQuoteSubtitle
              }
            }
          }
          fieldCellarDoorOurPlace {
            entity {
              ... on Drupal_ParagraphStandardContentAndImageCarro {
                id
                fieldStandardContentBlock {
                  entity {
                    ... on Drupal_ParagraphStandardContentBlock {
                      id
                      fieldStandardContentCta2Link {
                        options
                        title
                        url {
                          path
                        }
                      }
                      fieldStandardCta {
                        options
                        title
                        url {
                          path
                        }
                      }
                      fieldStandardDescription
                      fieldStandardSubtitle
                      fieldStandardTitle
                      fieldStandardContentDownload {
                        entity {
                          url
                          uri {
                            url
                          }
                        }
                      }
                    }
                  }
                }
                fieldStandardImageCarrousel {
                  entity {
                    ... on Drupal_ParagraphStandardImageCarrousel {
                      id
                      fieldCarrouselImageItem {
                        entity {
                          ... on Drupal_ParagraphCarrouselImageItem {
                            id
                            fieldImageItem {
                              url
                              title
                              alt
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          fieldCellarDoorTastingExp {
            entity {
              ... on Drupal_ParagraphStandardContentAndImageCarro {
                id
                fieldStandardContentBlock {
                  entity {
                    ... on Drupal_ParagraphStandardContentBlock {
                      id
                      fieldStandardContentCta2Link {
                        options
                        title
                        url {
                          path
                        }
                      }
                      fieldStandardCta {
                        options
                        title
                        url {
                          path
                        }
                      }
                      fieldStandardDescription
                      fieldStandardSubtitle
                      fieldStandardTitle
                      fieldStandardContentDownload {
                        entity {
                          url
                          uri {
                            url
                          }
                        }
                      }
                    }
                  }
                }
                fieldStandardImageCarrousel {
                  entity {
                    ... on Drupal_ParagraphStandardImageCarrousel {
                      id
                      fieldCarrouselImageItem {
                        entity {
                          ... on Drupal_ParagraphCarrouselImageItem {
                            id
                            fieldImageItem {
                              url
                              title
                              alt
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          fieldCellarDoorCustomExp {
            entity {
              ... on Drupal_ParagraphStandardContentAnd2imgBlock {
                id
                fieldStandardTextBlock {
                  entity {
                    ... on Drupal_ParagraphStandardContentBlock {
                      id
                      fieldStandardContentCta2Link {
                        options
                        title
                        url {
                          path
                        }
                      }
                      fieldStandardCta {
                        options
                        title
                        url {
                          path
                        }
                      }
                      fieldStandardDescription
                      fieldStandardSubtitle
                      fieldStandardTitle
                    }
                  }
                }
                fieldStandard2imagesBlock {
                  entity {
                    ... on Drupal_ParagraphStandardTwoImagesBlock {
                      id
                      fieldLeftImage {
                        alt
                        url
                        title
                      }
                      fieldRightImage {
                        alt
                        title
                        url
                      }
                    }
                  }
                }
              }
            }
          }
          fieldCellarDoorRestaurant {
            entity {
              ... on Drupal_ParagraphStandardContentAndImageCarro {
                id
                fieldStandardContentBlock {
                  entity {
                    ... on Drupal_ParagraphStandardContentBlock {
                      id
                      fieldStandardCta {
                        options
                        title
                        uri
                        url {
                          path
                        }
                      }
                      fieldStandardContentCta2Link {
                        options
                        title
                        url {
                          path
                        }
                      }
                      fieldStandardDescription
                      fieldStandardSubtitle
                      fieldStandardTitle
                      fieldStandardContentDownload {
                        targetId
                        entity {
                          url
                          uri {
                            url
                          }
                        }
                      }
                    }
                  }
                }
                fieldStandardImageCarrousel {
                  entity {
                    ... on Drupal_ParagraphStandardImageCarrousel {
                      id
                      fieldCarrouselImageItem {
                        entity {
                          ... on Drupal_ParagraphCarrouselImageItem {
                            id
                            fieldImageItem {
                              url
                              targetId
                              alt
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          fieldCellarDoorExpTitle
          fieldCellarDoorExpSubtitle
          fieldCellarDoorExperiences {
            entity {
              ... on Drupal_Paragraph {
                ... on Drupal_ParagraphExperienceItem {
                  fieldExperienceItem {
                    entity {
                      ... on Drupal_NodeExperience {
                        status
                        nid
                        path {
                          alias
                        }
                        fieldOtherExperiencesTitle
                        fieldExperienceImageDesktop {
                          alt
                          url
                          title
                        }
                        fieldExperienceName
                        fieldExperienceImageMobile {
                          alt
                          title
                          url
                        }
                        fieldExperienceDescription
                        fieldExperienceDetails {
                          entity {
                            ... on Drupal_ParagraphStandardContentBlock {
                              id
                              fieldStandardCta {
                                options
                                title
                                url {
                                  path
                                }
                              }
                              entityId
                            }
                          }
                        }
                        fieldExperienceBookingDetails {
                          entity {
                            ... on Drupal_ParagraphExperienceBookingDetails {
                              id
                              fieldBookingDetailsTitle
                              fieldBookingDetailsProdCode
                              fieldBookingDetailsPrice
                              fieldBookingDetailsDuration
                              fieldBookingDetailsBookCta {
                                title
                                url {
                                  path
                                }
                                options
                              }
                            }
                          }
                        }
                        fieldExperienceCategory {
                          entity {
                            ... on Drupal_TaxonomyTermXpCategories {
                              tid
                              name
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          fieldNextCellarDoor {
            entity {
              ... on Drupal_ParagraphStandardQuoteBlock {
                id
                fieldQuoteBackgroundDesktop {
                  alt
                  url
                  title
                }
                fieldQuoteBackgroundMobile {
                  alt
                  url
                  title
                }
                fieldQuoteThumbnailImage {
                  alt
                  url
                  title
                }
                fieldQuoteThumbnailPosition
                fieldStandardQuoteSubtitle
                fieldQuoteText
                fieldStandardQuoteCta {
                  options
                  title
                  url {
                    path
                  }
                }
              }
            }
          }
        }
      }
      eventsQuery: nodeQuery(
          filter: {conditions: [
            {field: "type", value: "events", operator: EQUAL},
            {field: "field_event_country", value: [$marketId], operator: EQUAL},
            {operator: EQUAL, field: "status", value: "1"}
          ]}
          limit: 1000
        ) {
        entities {
          ... on Drupal_NodeEvents {
            title
            path{
              alias
            }
            fieldEventTypes
            fieldEventStartDate {
              value
              date
            }
            fieldEventEndDate {
              value
              date
            }
            fieldEventCountry {
              targetId
            }
            fieldEventCountryLocation
            fieldEventLocation
            fieldEventCover {
              height
              url
              width
              targetId
              alt
              title
            }
            fieldEventCoverMobile {
              height
              url
              width
              targetId
              alt
              title
            }
            fieldEventDescription
            fieldEventHours
          }
        }
      }
    }
  }
`

export default VisitWinery
