import styled from '@emotion/styled'
import { mediaMax } from '@/styles/mixins'

export const Wrapper = styled.div`
  width: 100%;

  #Tock_widget_container, #Tock_widget_container2 {
    width: fit-content !important;

    ${mediaMax.xs} {
      width: 100% !important;

      .TockButtonWidgetContainer {
        width: 100% !important;
      }
    }

    .TockButton-link:focus, .TockButton-link:focus-visible, .TockButton:focus-visible {
      outline: none !important;
    }

    .TockButton-buttonContainer {
      border-radius: 0 !important;
      width: 100% !important;
    }

    .TockLogo-container.TockButton-iconBlue {
      display: none !important;
    }

    .TockButton.TockButton-blue {
      background: ${({ theme }) => theme.colors.green} !important;
      white-space: nowrap !important;
      text-transform: uppercase !important;
      font-size: 1.2rem !important;
      font-family: ${({ theme }) => theme.fonts.sansSerif} !important;
      font-weight: bold !important;
      border-radius: 0 !important;
      border: 1px solid ${({ theme }) => theme.colors.green} !important;
      color: ${({ theme }) => theme.colors.white} !important;

      > span {
        text-transform: uppercase !important;
        font-size: 1.2rem !important;
        font-family: ${({ theme }) => theme.fonts.sansSerif} !important;
        font-weight: bold !important;
      }
    }
  }
`
