import * as React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useSetRecoilState } from 'recoil'
import { popupManager, popupDataManager } from '@/recoil/popup'
import { EVENT_POPUP_ID } from '@/components/Popup/config'

import LocalizedGatsbyLink from '@/components/LocalizedGatsbyLink'

import { formatDay } from '@/utils/date'

import {
  Container,
  Wrapper,
  DateWrapper,
  DateItem,
  DateSeparator,
  Day,
  Month,
  Year,
  InfosWrapper,
  Name,
  Hours,
  References
} from './style'

const EventCard = ({ item }) => {
  const setPopup = useSetRecoilState(popupManager)
  const setPopupData = useSetRecoilState(popupDataManager)

  const getStatus = () => ((new Date()).getTime() > (new Date(item.date)).getTime()) ? 'closed' : 'open'

  // const handleClick = () => {
  //   setPopupData(item)
  //   setPopup(EVENT_POPUP_ID)
  // }

  return (
    <Container>
      <LocalizedGatsbyLink to={`${item.url}`}>
      <Wrapper className={ `status-${getStatus()}` }>
        <DateWrapper>
          <DateItem>
            <Day>
              { moment(item.startDate).format('DD') }
            </Day>
            <Month>
              {moment(item.startDate).format('MMMM') }
            </Month>
            <Year>
              {moment(item.startDate).format('YYYY') }
            </Year>
          </DateItem>
          {item.endDate && (
            <>
              <DateSeparator />
              <DateItem>
                <Day>
                  { moment(item.endDate).format('DD') }
                </Day>
                <Month>
                  {moment(item.endDate).format('MMMM') }
                </Month>
                <Year>
                  {moment(item.endDate).format('YYYY') }
                </Year>
              </DateItem>
            </>
          )}
        </DateWrapper>
        <InfosWrapper>
          <Name>{ item.name }</Name>
          <Hours>{ item.hours }</Hours>
          <References>@{ item.ref } - { item.country }</References>
        </InfosWrapper>
      </Wrapper>
      </LocalizedGatsbyLink>
    </Container>
  )
}

EventCard.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    ref: PropTypes.string,
    hours: PropTypes.string,
    country: PropTypes.string,
    city: PropTypes.string,
    images: PropTypes.arrayOf(PropTypes.string)
  })
}

export default EventCard
