import Button from '@/components/Button'
import React from 'react'
import resolveAssetURL from "@/utils/resolveAssetURL";

export const getButtons = (data = [], file = '') => {
  const buttons = []

  if (data.forEach) {
    data.forEach((b, index) => {
      if (b) {
        let button = null

        if (b?.options?.attributes?.tock) {
          button = (
            <div
              key={ index }
              id='Tock_widget_container'
              data-tock-display-mode='Button'
              data-tock-color-mode='Blue'
              data-tock-locale='en-nz'
              data-tock-timezone='Pacific/Auckland'
              data-tock-offering={ b.url.path.split('/')[1] || null }
            >
              {b.title}
            </div>
          )
        } else {
          if (b?.options?.attributes?.file) {
            button = <Button target='_blank' type={ b?.options?.attributes?.class || 'green' } key={ index } to={ resolveAssetURL(file) }>{ b.title }</Button>
          } else {
            button = <Button type={ b?.options?.attributes?.class || 'green' } key={ index } to={ b.url.path }>{ b.title }</Button>
          }
        }

        buttons.push(button)
      }
    })
  }

  return buttons
}
