import styled from '@emotion/styled'

import { mediaMax, sectionSubtitle, sectionTitle } from '@/styles/mixins'

import WysiwygBase from '@/components/Wysiwyg'

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 7rem 0;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.lightVanilla3};
  ${mediaMax.xs}{
    overflow: hidden;
  }
`

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
`

export const Content = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`

export const Subtitle = styled.span`
  ${sectionSubtitle()}
  color: ${({ theme }) => theme.colors.lightGreen};
  margin-bottom: 3rem;
`

export const Title = styled.span`
  ${sectionTitle()}
  color: ${({ theme }) => theme.colors.brown};
  // margin-bottom: 4.4rem;

  span {
    color: ${({ theme }) => theme.colors.green};
  }

  ${mediaMax.xs} {
    font-size: 4.2rem;
  }
`

export const Wysiwyg = styled(WysiwygBase)`
  width: 80%;
  font-size: 1.2rem;
  letter-spacing: 0.1rem;
  color: ${({ theme }) => theme.colors.grey};
`
