import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useRecoilValue } from 'recoil'
import { marketCodeManager } from '@/recoil/marketCode'
import { GridContainer } from '@/components/Grid'
import EventSlider from '@/components/Block/Sliders/EventSlider'
import Filters from '@/components/Filters'

import {
  Container,
  Wrapper,
  Content,
  Subtitle,
  Title,
  Wysiwyg
} from './style'

const Events = ({ items, title = '', subtitle = '', text = '', filters = null }) => {
  const [data, setData] = useState(items)
  const marketCode = useRecoilValue(marketCodeManager)

  const countryCode = new Intl.DisplayNames(
    [marketCode.split('-')[0]], { type: 'region' }
  )

  const filterData = (activeFilter) => {
    const results = items.filter((item) => countryCode.of(item.country) === activeFilter)
    setData(activeFilter === 'all' ? items : results)
  }

  return (
    <Container>
      <GridContainer>
        <Wrapper>
          <Content>
            {
                !!subtitle.length && (
                  <Subtitle dangerouslySetInnerHTML={ { __html: subtitle } } />
                )
              }
            {
                !!title.length && (
                  <Title dangerouslySetInnerHTML={ { __html: title } } />
                )
              }
            {
                !!text.length && (
                  <Wysiwyg>{ text }</Wysiwyg>
                )
              }
          </Content>
        </Wrapper>
      </GridContainer>
      {filters &&
        <Filters
          label=''
          placeholder={ filters.placeholder }
          filtersItems={ filters.items }
          onChangeCallback={ filterData }
        />}
      <EventSlider items={ data } />
    </Container>
  )
}

Events.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string),
    ref: PropTypes.string,
    image: PropTypes.string,
    price: PropTypes.number
  }))
}

export default Events
