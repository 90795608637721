import * as React from 'react'
import PropTypes from 'prop-types'

import { SwiperSlide } from 'swiper/react'

import { GridContainer } from '@/components/Grid'
import Arrows from '@/components/Block/Sliders/atoms/Arrows'
import EventCard from './EventCard'

import {
  Container,
  SwiperContent,
  ActionWrapper
} from './style'

const EventSlider = ({ items }) => {
  const getFirstActiveItemIndex = () => items.findIndex((item, i) => {
    return (new Date()).getTime() <= (new Date(item.date)).getTime()
  })

  return (
    <Container>
      <GridContainer>
        <SwiperContent initialSlide={ getFirstActiveItemIndex() } slidesPerView='auto'>
          {
            items.map((v, k) => (
              <SwiperSlide key={ `event-${k}` }>
                <EventCard item={ v } />
              </SwiperSlide>
            ))
          }
          <ActionWrapper>
            <Arrows display='inverted' />
          </ActionWrapper>
        </SwiperContent>
      </GridContainer>
    </Container>
  )
}

EventSlider.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    formattedDate: PropTypes.string,
    date: PropTypes.string,
    ref: PropTypes.string,
    from: PropTypes.string,
    to: PropTypes.string,
    country: PropTypes.string,
    city: PropTypes.string,
    zip: PropTypes.string,
    images: PropTypes.arrayOf(PropTypes.string)
  }))
}

export default EventSlider
