import styled from '@emotion/styled'

import { mediaMax } from '@/styles/mixins'

export const Container = styled.div`
  width: 100%;
  margin-bottom: 4rem;
`

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.1rem;
  font-weight: bold;
  letter-spacing: 1px;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.grey}`};
`
export const ItemsList = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  &.empty {
    opacity: 0;
    pointer-events: none;
    touch-action: none;
    visibility: hidden;
  }
`

export const SortLabel = styled.div`
  text-transform: uppercase;
  padding: 2.2rem;
  color: ${({ theme }) => `${theme.colors.grey}`};

  ${mediaMax.xs} {
    padding: 0;
  }
`

export const Item = styled.div`
  position: relative;
  text-transform: uppercase;
  padding: 2.2rem;
  cursor: pointer;
  color: ${({ theme }) => `${theme.colors.grey}7a`};

  &:not(:last-child):after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 1px;
    height: 50%;
    background-color: ${({ theme }) => `${theme.colors.grey}`};
  }

  &.active:before {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 2px;
    background: ${({ theme }) => `${theme.colors.green}`};
  }

  &.active {
    color: ${({ theme }) => `${theme.colors.green}`};
  }
`
